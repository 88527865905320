import $ from 'jquery'
import '../../common/search_bar/index.scss'
import './main.scss'

// Navigation toggle on mobile.
$(document).on('click', '.site-header__nav-toggle', function (event) {
  event.preventDefault()

  $('.site-header').toggleClass('site-header--mobile-open')
})

// Click outside header.
$(document).on('click', function (event) {
  if ($(event.target).closest('.site-header').length === 0) {
    $('.site-header').removeClass('site-header--mobile-open')
  }
})

// Dropdown toggle.
const dropdownActiveClass = 'site-header--dropdown-active'

$(document).on(
  'click',
  '.site-header__nav__item--has-dropdown > a',
  function (event) {
    const $target = $(event.target)
    const item = $(this).closest('.site-header__nav__item--has-dropdown')

    if (
      $target.hasClass('site-header__nav__item__label') &&
      item.hasClass('site-header__nav__item--type-page')
    ) {
      // if current item is Page with subpages and clicked to name of Page -> just go to this page
      return
    }

    event.preventDefault()
    event.stopPropagation()

    const isActive = item.hasClass(dropdownActiveClass)

    if (!$('body').hasClass('nav-left')) {
      $('.' + [dropdownActiveClass]).removeClass(dropdownActiveClass)
    }

    localStorage.setItem('site-dropdown-' + item.attr('id'), !isActive)

    toggleDropdown(item, !isActive)
  }
)

function toggleDropdown(dropdownContainer, show) {
  const dropdown = dropdownContainer.find('.site-header__dropdown')[0]
  const shouldSlide = dropdownContainer.hasClass(
    'site-header--dropdown-slide-animation'
  )

  if (shouldSlide) {
    const oldTransition = dropdown.style.transition
    dropdown.style.transition = 'none'

    const oldHeight = dropdown.offsetHeight
    dropdownContainer.toggleClass(dropdownActiveClass, show)
    const newHeight = dropdown.offsetHeight

    dropdown.style.height = `${oldHeight}px`
    dropdown.style.visibility = 'visible'
    dropdown.style.opacity = 1
    void dropdown.offsetHeight
    dropdown.style.transition = oldTransition
    dropdown.style.height = `${newHeight}px`
    setTimeout(() => {
      dropdown.style.visibility = ''
      dropdown.style.opacity = ''
      dropdown.style.height = ''
      void dropdown.offsetHeight
    }, 300)
  } else {
    dropdownContainer.toggleClass(dropdownActiveClass, show)
  }
}

// Show/hide dropdown on load of page based previous action of user (only for left navigation)
// NOTE: We moved this to an inline script in header.liquid to prevent flashes

// Click outside dropdown.
$(document).on('click', () =>
  $(
    'body:not(.nav-left) .site-header--dropdown-active, .site-header__nav__item--login'
  ).removeClass('site-header--dropdown-active')
)

// Search page and modal window
$(document).on('click', '.site-header__nav__item--search > a', function (e) {
  e.stopPropagation()

  // if we are on the search page already -> just focus field
  let field = $('.site__search__wrapper input[type=search]')
  if (document.location.pathname == '/search' && field.length > 0) {
    field.trigger('focus')
    return
  }

  let search
  search = $('.global-search')
  // If can't find .global-search, maybe it's a customized/old header code.
  if (search.length < 1) {
    search = $('.site-header__search')
    if (search.length > 1) {
      // If found 2 divs with .site-header__search, the first one is the global one.
      search = search.first()
    }
  }
  const closed_cls = 'site-header__search--closed'
  const wrapper = search.find('.site-header__search__wrapper')
  const form = search.find('form')
  field = form.find('input[type=search]')

  search.find('.site-header_search__form__expand').on('click', function () {
    form.attr('data-remote', '')
    return form.trigger('submit')
  })

  const close_search = function () {
    search.addClass(closed_cls)
    return $(document).off('click.search-modal').off('keyup.search-modal')
  }

  if (search.hasClass(closed_cls)) {
    search.removeClass(closed_cls)

    field.val('')
    wrapper.removeClass('search-results-loaded')

    $(document).on('click.search-modal', function (e) {
      if (!$(e.target).parents().hasClass('site-header__search__wrapper')) {
        return close_search()
      }
    })
    $(document).on('keyup.search-modal', function (e) {
      if (e.key == 'Escape') {
        return close_search()
      }
    })

    // This has a transition, so we need to wait for it to finish before focusing
    setTimeout(() => field.trigger('focus'), 250)
  } else {
    close_search()
  }
})

// Search modal opening from sidebar on group forum
$(document).on('input', '.sidebar__search_trigger', function (e) {
  const trigger_value = e.target.value
  e.target.blur()
  e.target.value = ''
  e.stopPropagation()

  const search = $('.sidebar__search')
  const closed_cls = 'site-header__search--closed'
  const wrapper = search.find('.site-header__search__wrapper')
  const form = search.find('form')
  let field = form.find('#sidebar-search-field')

  search.find('.site-header_search__form__expand').on('click', function () {
    form.attr('data-remote', '')
    return form.trigger('submit')
  })

  const close_search = function () {
    search.addClass(closed_cls)
    return $(document).off('click.search-modal').off('keyup.search-modal')
  }

  if (search.hasClass(closed_cls)) {
    search.removeClass(closed_cls)
    field.val(trigger_value)
    field.trigger('focus')
    wrapper.removeClass('search-results-loaded')

    $(document).on('click.search-modal', function (e) {
      if (!$(e.target).parents().hasClass('site-header__search__wrapper')) {
        return close_search()
      }
    })
    $(document).on('keyup.search-modal', function (e) {
      if (e.key == 'Escape') {
        return close_search()
      }
    })
  } else {
    close_search()
  }
})

$('.search-field-with-close').each(function () {
  const field = $(this)
  const wrapper = field.closest('form').parent()

  wrapper.find('.clear-field').on('click', function (e) {
    e.stopPropagation()
    e.preventDefault()
    field.val('')
    wrapper.removeClass('search-results-loaded')
  })
})

// Section navigation for landing pages
$(document).on('click', '.site-header__nav__item--section > a', function () {
  $('.site-header').removeClass('site-header--mobile-open')
})

// Dynamic tooltip on left nav for links that overflow
const initializeTooltipsOnOverflowingNavItems = () => {
  const items = $(
    'body.nav-left ul.site-header__nav > .site-header__nav__item > a > .site-header__nav__item__label:visible, body.nav-left ul.site-header__nav > .site-header__nav__item .site-header__dropdown__item__label:visible'
  )
  items.each(function () {
    const item = $(this)
    const container = item.parent()
    const right = item[0].getBoundingClientRect().right
    const containerRight = container[0].getBoundingClientRect().right
    if (right > containerRight) {
      container.addClass(
        'tooltip--bottom-left tooltip--almost-fullwidth tooltip--wrap'
      )
      container.attr('data-tooltip', item.text())
      container.attr('data-initialized-tooltip-for-overflowing-nav-item', true)
    } else if (
      container.attr('data-initialized-tooltip-for-overflowing-nav-item')
    ) {
      container.removeClass(
        'tooltip--bottom-left tooltip--almost-fullwidth tooltip--wrap'
      )
      container.attr('data-tooltip', null)
      container.attr('data-initialized-tooltip-for-overflowing-nav-item', false)
    }
  })
  setTimeout(initializeTooltipsOnOverflowingNavItems, 2000)
}
$(initializeTooltipsOnOverflowingNavItems)
